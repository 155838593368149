<template>
  <div class="home">
    <HeadNav />
    <div class="content">
      <div class="cLeft">
        <div class="left_one">
          <div class="fs20 fw700">{{ $t("t.homeView.homeView1") }}</div>
          <!-- <div class="gray fs18">1共6个</div> -->
        </div>
        <div class="line"></div>
        <div class="title fs30">{{ $t("t.homeView.homeView2") }}</div>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="postalCode">
            <p class="label">{{ $t("t.homeView.homeView3") }}</p>
            <el-input :placeholder="$t('t.homeView.homeView4')" v-model="form.postalCode" clearable style="width: 32.5%">
            </el-input>
          </el-form-item>
          <el-form-item prop="detailedAddress">
            <div v-if="isShow">
              <p class="label">{{ $t("t.homeView.homeView5") }}</p>
              <el-select v-model="form.detailedAddress" :placeholder="$t('t.homeView.homeView6')" style="width: 32.5%">
                <el-option v-for="item in addresses" :key="item.Id" :label="item.Addr" :value="item.Id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="number">
            <p class="label mb1">{{ $t("t.homeView.homeView7") }}</p>
            <!-- 数字列表  -->
            <div class="numbtn">
              <div v-for="item in numList" :key="item.value">
                <el-button type="info" plain circle :class="item.value === form.number ? 'checkButton' : ''"
                  style="margin-right: 0.5vw" v-model="form.number" @click="numbtnClick(item)">
                  <div v-if="item.number < 10">
                    &nbsp;{{ item.value }}&nbsp;
                  </div>
                  <div v-else>{{ item.value }}</div>
                </el-button>
              </div>
            </div>
          </el-form-item>
          <div class="nextbtn">
            <el-button class="btn" type="info" @click="getQuote('form')">{{ $t("t.homeView.homeView8") }}<i class="el-icon-right"></i></el-button>
          </div>
        </el-form>
      </div>
      <div class="cRight">
        <img src="../assets/images/Beauty.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import HeadNav from "../components/HeadNav.vue";
import { reqgethouse, } from "@/api/index";

export default {
  name: "homeView",
  data() {
    return {
      form: {
        postalCode: "", //邮政编码
        detailedAddress: "", //详细地址
        number: "", //入住人数
      },
      isShow: false,
      rules: {
        postalCode: [
          { required: true, message: "Postcode cannot be empty", trigger: "blur" },
        ],
        // postalCode: [
        //   { required: true, message: $t(t.homeView.homeView8), trigger: "blur" },
        // ],
        detailedAddress: [
          { required: true, message: "Full address cannot be empty", trigger: "change" },
        ],
      },
      value: "",
      numList: [
        {
          number: 1,
          value: "1",
        },
        {
          number: 2,
          value: "2",
        },
        {
          number: 3,
          value: "3",
        },
        {
          number: 4,
          value: "4",
        },
        {
          number: 5,
          value: "5",
        },
        {
          number: 6,
          value: "6",
        },
        {
          number: 8,
          value: "8",
        },
        {
          number: 9,
          value: "9",
        },
        {
          number: 10,
          value: "10",
        },
        {
          number: 11,
          value: "11",
        },
        {
          number: 12,
          value: "12",
        },
      ],
      addresses: [],
    };
  },
  components: {
    HeadNav,
  },
  watch: {
    "form.postalCode"(newValue) {
      if (newValue) {
        this.isShow = true;
        this.getAddress();
      } else {
        this.isShow = false;
      }
    },
  },
  created() { },
  methods: {
    //获取详细地址
    getAddress() {
      // this.form.postalCode,
      const params = {
        postal_code: this.form.postalCode
      }
      reqgethouse(params).then((res) => {
        if (res.code == 200) {
          this.addresses = res.data;
          console.log(this.addresses, "详细地址");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //选中人数
    numbtnClick(item) {
      this.form.number = item.value;
      console.log(this.form.number, "人数赋值！");
    },
    //获取报价
    getQuote(form) {
      console.log(this.form.detailedAddress, "form.detailedAddress")
      localStorage.setItem('hid', this.form.detailedAddress);
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.number == "" || this.form.number == null) {
            this.$message.error("Select the number of people!");
            return;
          } else {
            this.$router.push({
              path: "/accountConfirmation",
              query: {
                peopleNum: this.form.number,
              },
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}

.fs18 {
  font-size: 0.9375vw;
}

.fs20 {
  font-size: 1.0416vw;
}

.fw700 {
  font-weight: 700;
}

.gray {
  color: #4e5866;
}

.content {
  width: 80vw;
  padding: 1.85185vh 6.042vw;
  margin: 0 auto;
  // background-color: antiquewhite;
  display: flex;

  .cLeft {
    width: 51.4583333vw;
    height: 83.3333vh;
    padding: 0 1.927vw;

    .left_one {
      padding: 1.85185vh 0;
      display: flex;
      justify-content: space-between;
    }

    .line {
      width: 100%;
      /* 线的宽度 */
      height: 2px;
      /* 线的高度 */
      background: linear-gradient(to right,
          #a3ce34,
          #cccccc,
          #cccccc,
          #cccccc,
          #cccccc);
      /* 线性渐变 */
      opacity: 1;
    }

    .title {
      // margin: 30px, 0, 25px, 0;
      margin-top: 1.5625vw;
      margin-bottom: 1.30208vw;
    }
  }

  .cRight img {
    width: 36.77083vw; //706px
  }
}

.numbtn {
  display: flex;
  flex-wrap: wrap;
  width: 20.83vw;
  height: 12vh;
  justify-items: flex-start;
}

.mb1 {
  margin-bottom: 1vw;
}

.numbtn .btn {
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  margin-right: 2vw;
}

.footerBtn {
  margin: 0 auto;
}

.fs30 {
  font-size: 1.5625vw;
}

.label {
  color: #4c5665;
  font-size: 0.833vw; //16px
  margin-top: 1.6666vw; //18px
}

.el-button--info.is-plain:focus,
.el-button--info.is-plain:hover {
  background: #a3ce34;
  border-color: #a3ce34;
  color: #fff;
}

.nextbtn {
  margin-top: 29.629629vh; //320px
  text-align: center;

  .btn {
    width: 23.0208vw;
    height: 5.185185vh;
    opacity: 1;
    background: #a3ce34;
    border-radius: 27.5px;
    color: rgba(255, 255, 255, 1);
    font-size: 1.04166vw; //20px;
    border: none;
  }
}

.checkButton {
  background: #a3ce34;
  border-color: #a3ce34;
  color: #fff;
}
</style>
