<template>
  <div class="head">
    <div class="head">
      <div class="logo">
        <img src="../assets/images/header-logo.png" alt="" height="100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadNav",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.head {
  width: 100%;
  height: 11.1vh;
  background-color: #a3ce34;
  .logo {
    padding-left: 4.583vw;
  }
  .logo img {
    height: 11.1vh;
    padding-left: 4.583vw;
  }
}
</style>
