// 引入axios
// 引入Vue Router
import router from "../router";
import axios from "axios";

// 创建axios实例对象
let requests = axios.create({
  // 基础路径
  baseURL: "https://billing.general-energy.co.uk:8012/home",
  // baseURL: "http://192.168.1.12:8012/home",
  // 设置请求超时的时间
  timeout: 3000,
});

// 请求拦截器：在发请求之前，请求拦截器可以检测到，可以在发出请求之前做一些事情
requests.interceptors.request.use((config) => {
  // config:配置对象，对象里边有一个属性很重要，headers请求头
  if (localStorage.getItem("token")) {
    config.headers.Token = localStorage.getItem("token"); //把localStorage的token放在Token里
  } else {
    
    // if (router.history.current.path != "/tenantryLogin") {
    //   //不是登录接口时 没有token 重新登录
    //   router.push({
    //     path: "/tenantryLogin",
    //   });
    // }
  }
  return config;
});

// 响应拦截器
requests.interceptors.response.use(
  (res) => {
    // 成功的回调函数：服务器响应的数据回来以后，响应拦截器可以检测到，可以做一些事情
    return res.data;
  },
  (error) => {
    // 响应失败的回调函数
    return Promise.reject(new Error("faile"));
  }
);

// 对外暴露
export default requests;
