import Vue from "vue";
import VueI18n from "vue-i18n"; // 引入vue-i18n
 
Vue.use(VueI18n); // 全局挂载
let Language = navigator.language;
if (Language === "zh-CN") {
  Language = "zh";
}else{
  Language = "en";
}
localStorage.setItem("Language", Language); // 存入localStorage
console.log(Language); // 输出当前浏览器的语言设置
export const i18n = new VueI18n({
  locale: localStorage.getItem("Language"), // 从localStorage中获取 默认英文
  // locale : 'en', // 语言表识
  messages: {
    zh: require("./lang/zh"), // 中文语言包
    en: require("./lang/en") // 英文语言包
  }
});
//导出
export default i18n;
 
 