//邮箱
const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\\w+)*\.\w+([-.]\w+)*$/;
//仅输入数字（电话、传真号）
const onlyNum = /[^\d]/g;
//输入6-32 个字符，必须包含字母(收款用户名)
const letter = /^(?=.*[a-zA-Z]).{6,32}$/;
//金额校验（不能超过三位小数）
const moneyRegex3 = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,3})?$/;
//金额校验（不能超过四位小数）
const moneyRegex4 = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/;
//正整数
const IntegerPlusRegex = /^\+?[0-9][0-9]*$/;
//密码须包含数字、字母两种元素，且密码位数为7-16位
const password = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,16}$/
//模块导出
export default {
    //正则
    emailRegex: emailRegex,
    onlyNum: onlyNum,
    letter: letter,
    moneyRegex3: moneyRegex3,
    moneyRegex4: moneyRegex4,
    IntegerPlusRegex: IntegerPlusRegex,
    passwordChecked: password,
}
