/**
 * 当前模块进行API统一管理
 * 当项目很小时，完全可以在组建中发请求，也就是说可以在组件挂载或者渲染的时候发请求
 * 对外暴露该接口，当有组建使用的时候可以直接拿到相关的数据
 *
 */

import requests from "./request";
//  const Token = localStorage.getItem('token');

//询价-获取地址接口
export const reqgethouse = (params) =>
  requests({ url: "/v1/gethouse", method: "post", data: params });
//询价-获取验证码
export const reqgetcode = (params) =>
  requests({ url: "/v1/getcode", method: "post", data: params });
//询价-注册接口
export const reqparams = (params) =>
  requests({ url: "/v1/register", method: "post", data: params });
//询价-登录接口
export const reqlogin = (params) =>
  requests({ url: "/v1/login", method: "post", data: params });
//询价-账单设置-获取商品接口
export const reqgetgoods = (params) =>
  requests({ url: "/v1/getgoods", method: "post", data: params });
//询价-账单设置-下单接口
export const reqplaceorder = (params) =>
  requests({ url: "/v1/placeorder", method: "post", data: params });
//询价-账单设置-添加室友接口
export const reqsetroomies = (params) =>
  requests({ url: "/v1/setroomies", method: "post", data: params });
//询价-添加室友-支付接口
export const reqpay = (params) =>
  requests({ url: "/v1/pay", method: "post", data: params });
//询价-室友-获取订单接口
export const reqgetorder = (params) =>
  requests({ url: "/v1/getorder", method: "post", data: params });
//租户||代理-修改密码接口
export const reqretrieve = (params) =>
  requests({ url: "/v1/retrieve", method: "post", data: params });
//租户获取房源信息接口
export const reqgetuser = (params) =>
  requests({ url: "/v1/getuser", method: "post", data: params });
//租户设置房源信息接口
export const reqsetuser = (params) =>
  requests({ url: "/v1/setuser", method: "post", data: params });
//租户获取房屋信息接口
export const reqgetuserhouse = (params) =>
  requests({ url: "/v1/getuserhouse", method: "post", data: params });
//租户获取室友信息接口
export const reqgetroomies = (params) =>
  requests({ url: "/v1/getroomies", method: "post", data: params });
//租户室友解绑接口
export const requnbind = (params) =>
  requests({ url: "/v1/unbind", method: "post", data: params });
//租户室友邀请接口
export const reqInviteroomies = (params) =>
  requests({ url: "/v1/inviteroomies", method: "post", data: params });
//q&a接口
export const reqgetQA = (params) =>
  requests({ url: "/v1/getQA", method: "post", data: params });
//邀请朋友列表接口
export const recommendlist = (params) =>
  requests({ url: "/v1/recommendlist", method: "post", data: params });
//邀请朋友接口
export const reqrecommend = (params) =>
  requests({ url: "/v1/recommend", method: "post", data: params });
//代理所有房屋接口
export const reqgetuserhouses = (params) =>
  requests({ url: "/v1/getuserhouses", method: "post", data: params });
//代理修改房屋接口
export const reqsethouse = (params) =>
  requests({ url: "/v1/sethouse", method: "post", data: params });
//代理添加房屋接口
export const reqaddhouse = (params) =>
  requests({ url: "/v1/addhouse", method: "post", data: params });
//代理房屋能源概览接口
export const reqhouse = (params) =>
  requests({ url: "/v1/house", method: "post", data: params });
//资源列表-获取商品接口
export const reqgetgood = (params) =>
  requests({ url: "/v1/getgood", method: "post", data: params });
//资源列表-房屋用量详情接口
export const reqHousetype = (params) =>
  requests({ url: "/v1/Housetype", method: "post", data: params });
