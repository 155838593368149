import '@/assets/css/commom.css';
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import SliderVerify from 'slider-verify-v2';
import 'slider-verify-v2/lib/SliderVerify.css';
import Vue from 'vue';
import App from './App.vue';
import i18n from "./in18n/index";
import router from './router';
import store from './store';
import pattern from './utils/regular'; //正则校验
import countryCode from './utils/areaCode'; //国家区号

import 'element-ui/lib/theme-chalk/display.css';
Vue.use(i18n);
Vue.use(SliderVerify)
Vue.use(ElementUI)
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.prototype.$pattern = pattern;
Vue.prototype.$countryCode = countryCode;
// Vue.prototype.$axios = axios;
new Vue({
  router,
  store,
  i18n, //使用国际化
  render: h => h(App)
}).$mount('#app')
