import Vue from 'vue'
import VueRouter from 'vue-router'
import homeView from '../views/homeView.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: homeView,//首页
  },
  {
    path: '/accountConfirmation',
    name: 'accountConfirmation',//账号确认
    component: () => import(/* webpackChunkName: "accountConfirmation" */ '../views/accountConfirmation.vue')
  },
  {
    path: '/roomieConfirmation',
    name: 'roomieConfirmation',//室友账号确认
    component: () => import(/* webpackChunkName: "roomieConfirmation" */ '../views/roomieConfirmation.vue')
  },
  {
    path: '/roommateBill',
    name: 'roommateBill',//室友账单
    component: () => import(/* webpackChunkName: "addRoomie" */ '../views/roommateBill.vue')
  },
  {
    path: '/billingSetup',
    name: 'billingSetup',//账单设置
    component: () => import(/* webpackChunkName: "billingSetup" */ '../views/billingSetup.vue')
  },
  {
    path: '/addRoomie',
    name: 'addRoomie',//添加室友
    component: () => import(/* webpackChunkName: "addRoomie" */ '../views/addRoomie.vue')
  },
  {
    path: '/tenantry',
    name: 'tenantry',//租户首页
    component: () => import(/* webpackChunkName: "tenantryHome" */ '../views/secondPhase/tenantryHome.vue')
  },
  {
    path: '/agency',
    name: 'agency',//代理首页
    component: () => import(/* webpackChunkName: "agencyHome" */ '../views/secondPhase/agencyHome.vue')
  },
  {
    path: '/tenantrySignin',
    name: 'tenantrySignin',//租户注册
    component: () => import(/* webpackChunkName: "tenantrySignin" */ '../views/secondPhase/tenantrySignin.vue')
  },
  {
    path: '/agencySignin',
    name: 'agencySignin',//代理注册
    component: () => import(/* webpackChunkName: "agencySignin" */ '../views/secondPhase/agencySignin.vue')
  },
  {
    path: '/tenantryLogin',
    name: 'tenantryLogin',//租户登录
    component: () => import(/* webpackChunkName: "tenantryLogin" */ '../views/secondPhase/tenantryLogin.vue')
  },
  {
    path: '/agencyLogin',
    name: 'agencyLogin',//代理登录
    component: () => import(/* webpackChunkName: "agencyLogin" */ '../views/secondPhase/agencyLogin.vue')
  },
  {
    path: '/myhouse',
    name: 'myhouse',//我和我的房子
    component: () => import(/* webpackChunkName: "myhouse" */ '../views/secondPhase/myhouse.vue')
  },
  {
    path: '/recommendFriend',
    name: 'recommendFriend',//推荐朋友
    component: () => import(/* webpackChunkName: "recommendFriend" */ '../views/secondPhase/recommendFriend.vue')
  },
  {
    path: '/energybill',
    name: 'energybill',//我的能源账单
    component: () => import(/* webpackChunkName: "energybill" */ '../views/secondPhase/energybill.vue')
  },
  {
    path: '/allProperties',
    name: 'allProperties',//所有房产
    component: () => import(/* webpackChunkName: "energybill" */ '../views/secondPhase/allProperties.vue')
  },
  {
    path: '/housingEnergy',
    name: 'housingEnergy',//房屋能源概览
    component: () => import(/* webpackChunkName: "housingEnergy" */ '../views/secondPhase/housingEnergy.vue')
  },
  {
    path: '/setting',
    name: 'setting',//设置
    component: () => import(/* webpackChunkName: "setting" */ '../views/secondPhase/setting.vue')
  },
  {
    path: '/qa',
    name: 'qa',//QA
    component: () => import(/* webpackChunkName: "qa" */ '../views/secondPhase/qa.vue')
  },

  
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
