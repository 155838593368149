export const t = {
  // ex
  roommateBill: {
    roommateBill1: "Your Energy Bill",
    roommateBill2: "GBP",
    roommateBill3: "All",
    roommateBill4: "/Cost",
    roommateBill5: "Each Bill Payer",
    roommateBill6: "/Total Cost",
    roommateBill7: "Payers",
    roommateBill8: "Continue",
  },
  roomieConfirmation: {
    roomieConfirmation1: "Account Confirmation",
    roomieConfirmation2: "Please Confirm Your Account Details",
    roomieConfirmation3: "First Name",
    roomieConfirmation4: "Please Enter Your First Name",
    roomieConfirmation5: "Last Name",
    roomieConfirmation6: "Please Enter Your Last Name",
    roomieConfirmation7: "Please Choose Your Preferred Method to Receive the Verification Code (Phone  or Email)",
    roomieConfirmation8: "Your Phone Number",
    roomieConfirmation9: "Please Select the Area Code",
    roomieConfirmation10: "Please Enter Your Phone",
    roomieConfirmation11: "Email",
    roomieConfirmation12: "Please Enter Your Email Address",
    roomieConfirmation13: "Please Drag the Slider to Complete the Puzzle",
    roomieConfirmation14: "Code",
    roomieConfirmation15: "Enter Your Code",
    roomieConfirmation16: "I Have Read and Agree to the Terms of Service",
    roomieConfirmation17: "We Move In on the Lease Start Date",
    roomieConfirmation18: "Continue",
    roomieConfirmation19: "Existing Account",
    roomieConfirmation20: "Sign Up",
    roomieConfirmation21: "Account Verification",
    roomieConfirmation22: "Please Enter Your Account Details",
    roomieConfirmation23: "Phone/Email",
    roomieConfirmation24: "Password",
    roomieConfirmation25: "Continue",
    roomieConfirmation26: "Lease",
    roomieConfirmation27: "Verification Code Has Been Sent",
    roomieConfirmation28: "Please Try Again",
    roomieConfirmation29: "Please Enter Verification Code",
    roomieConfirmation30: "Please Enter Your First Name",
    roomieConfirmation31: "Please Enter Your Last Name",
    roomieConfirmation32: "Please Enter Your Phone",
    roomieConfirmation33: "Please Enter Your Email Address",
    roomieConfirmation34: "Please Enter a Valid Email Address",
    roomieConfirmation35: "Please Enter a Valid Email Address",
    roomieConfirmation36: "Please Enter Your Phone/Email",
    roomieConfirmation37: "Please Enter Your Password",
    roomieConfirmation38: "The Password Must Contain at Least 7 Characters, Including at Least One Uppercase Letter, One Lowercase Letter, and One Number",
    roomieConfirmation39: "Verification Code Has Been Sent",
    roomieConfirmation40: "Successful",
  },
  homeView: {
    homeView1: "Start",
    homeView2: "Please Enter Your Account Details",
    homeView3: "Post Code",
    homeView4: "EX:E97TA",
    homeView5: "Detail Address",
    homeView6: "Select",
    homeView7: "How many people will live in your new home?",
    homeView8: "GET A QUOTE",
    homeView9: "Postcode cannot be empty",
    homeView10: "Full address cannot be empty",
    homeView11: "Full Address",
    homeView12: "Select the number of people!",
  },
  billingSetup: {
    billingSetup1: "Billing Settings",
    billingSetup2: "Create Your Billing",
    billingSetup3: "Provided by QUANTUM NRG LTD",
    billingSetup4: "Learn More",
    billingSetup5: "Please Select",
    billingSetup6: "GBP",
    billingSetup7: "Add",
    billingSetup8: "Continue",
    billingSetup9: "Your Billing",
    billingSetup10: "GBP",
    billingSetup11: "Delete",
    billingSetup12: "All",
    billingSetup13: "/Cost",
    billingSetup14: "Each Bill Payer",
    billingSetup15: "Total Cost",
    billingSetup16: "Payers",
    billingSetup17: "Delete This Bill?",
    billingSetup18: "Cancel",
    billingSetup19: "Continue",
    billingSetup20: "Close",
    billingSetup21: "Delete Successful",
  },
  addRoomie: {
    addRoomie1: "Add Roommate Details",
    addRoomie2: "Set Up Payer",
    addRoomie3: "Next, Add Your Bill Payer's Name and Phone Number. We Will Then Send Them a Text Message to Create an Account",
    addRoomie4: "Payer",
    addRoomie5: "Name",
    addRoomie6: "Please Enter Your Name",
    addRoomie7: "Phone",
    addRoomie8: "Please Select the Area Code",
    addRoomie9: "Please Enter Your Phone",
    addRoomie10: "Please Enter Your Email Address",
    addRoomie11: "Add",
    addRoomie12: "Add Success",
    addRoomie13: "Continue",
    addRoomie14: "Your Billing",
    addRoomie15: "GBP",
    addRoomie16: "All",
    addRoomie17: "/Cost",
    addRoomie18: "Each Bill Payer",
    addRoomie19: "/Cost",
    addRoomie20: "Payers",
    addRoomie21: "Please Enter Your Name",
    addRoomie22: "Please Enter Your Phone",
    addRoomie23: "Please Enter Your Email Address",
    addRoomie24: "Please Enter a Valid Email Address",
    addRoomie25: "Please Enter a Valid Email Address",
  },
  accountConfirmation: {
    accountConfirmation1: "Account Confirmation",
    accountConfirmation2: "Confirm Your Account Details",
    accountConfirmation3: "First Name",
    accountConfirmation4: "Please Enter Your First Name",
    accountConfirmation5: "Last Name",
    accountConfirmation6: "Please Enter Your Last Name",
    accountConfirmation7: "Lease Term Starts",
    accountConfirmation8: "Lease Term Starts",
    accountConfirmation9: "Please Choose Your Preferred Method to Receive the Verification Code (Phone  or Email)",
    accountConfirmation10: "Phone",
    accountConfirmation11: "Please Select the Area Code",
    accountConfirmation10: "Phone",
    accountConfirmation12: "Email",
    accountConfirmation13: "Email Address",
    accountConfirmation14: "Please Drag the Slider to Complete the Puzzle",
    accountConfirmation15: "Code",
    accountConfirmation16: "I Have Read and Agree to the Terms of Service",
    accountConfirmation17: "We Move In On The Lease Start Date",

    accountConfirmation18: "Continue",
    accountConfirmation19: "Already Have An Account",
    accountConfirmation20: "Continue",

    
    accountConfirmation21: "Account Confirmation",
    accountConfirmation22: "Please Confirm Your Account Details",
    accountConfirmation23: "Phone/Email",
    accountConfirmation24: "Password",
    accountConfirmation25: "Lease Start Date",
    accountConfirmation26: "Select Date",
    accountConfirmation27: "Lease End Date",
    accountConfirmation28: "Select Date",
    accountConfirmation29: "Continue",
    accountConfirmation30: "Verification Code Has Been Sent",
    accountConfirmation31: "Please Try Again",
    accountConfirmation32: "Please Enter Your Code",
    accountConfirmation33: "Please Enter Your First Name",
    accountConfirmation34: "Please Enter Your Last Name",
    accountConfirmation35: "Please Enter Lease Start Date",
    accountConfirmation36: "Please Enter Lease End Date",
    accountConfirmation37: "Please Enter Your Phone",
    accountConfirmation38: "Please Enter Your Email",
    accountConfirmation39: "Please Enter a Valid Email Address",
    accountConfirmation40: "Please Enter a Valid Email Address",
    accountConfirmation41: "Please Enter Your Phone/Email",
    accountConfirmation42: "Please Enter Your Password",
    accountConfirmation43: "The Password Must Contain at Least 7 Characters, Including at Least One Uppercase Letter, One Lowercase Letter, and One Number",
    accountConfirmation44: "Please Enter Lease Start Date",
    accountConfirmation45: "Please Enter Lease End Date",
    accountConfirmation46: "Verification Code Has Been Sent",
    accountConfirmation47: "Sign Up Successful",
  },
  tenantrySignin: {
    tenantrySignin1: "Sign Up",
    tenantrySignin2: "First Name",
    tenantrySignin3: "Please Enter Your First Name",
    tenantrySignin4: "Last Name",
    tenantrySignin5: "Please Enter Your Last Name",
    tenantrySignin6: "Please Choose Your Preferred Method to Receive the Verification Code (Phone  or Email)",
    tenantrySignin7: "Phone",
    tenantrySignin8: "Please Select the Area Code",
    tenantrySignin9: "Please Enter Your Phone",
    tenantrySignin10: "Email",
    tenantrySignin11: "Please Enter a Valid Email Address",
    tenantrySignin12: "Please Drag the Slider to Complete the Puzzle",
    tenantrySignin13: "Code",
    tenantrySignin14: "Please Enter Your Code",
    tenantrySignin15: "I Have Read and Agree to the Terms of Service",
    tenantrySignin16: "We Move In on the Lease Start Date",
    tenantrySignin17: "Continue",
    tenantrySignin18: "Lease",
    tenantrySignin19: "Verification Code Has Been Sent",
    tenantrySignin20: "Please Try Again",
    tenantrySignin21: "Please Enter Your Code",
    tenantrySignin22: "Please Enter Your First Name",
    tenantrySignin23: "Please Enter Your Last Name",
    tenantrySignin24: "Please Enter Lease Start Date",
    tenantrySignin25: "Please Enter Lease End Date",
    tenantrySignin26: "Please Enter Your Phone",
    tenantrySignin27: "Please Enter Your Email",
    tenantrySignin28: "Please Enter a Valid Email Address",
    tenantrySignin29: "Please Enter a Valid Email Address",
    tenantrySignin30: "Verification Code Has Been Sent",
    tenantrySignin31: "Please Move the Slider Again",
    tenantrySignin32: "Verification Failed",
    tenantrySignin33: "Sign Up Successful",
  },
  tenantryLogin: {
    tenantryLogin1: "Tenant Login",
    tenantryLogin2: "Log In to Your Account",
    tenantryLogin3: "Please Enter Your Phone/Email",
    tenantryLogin4: "Please Enter Your Password",
    tenantryLogin5: "Forgot Password",
    tenantryLogin6: "Please Drag the Slider to Complete the Puzzle",
    tenantryLogin7: "Change Password",
    tenantryLogin8: "Phone/Email",
    tenantryLogin9: "Please Enter Your Phone",
    tenantryLogin10: "Sent",
    tenantryLogin11: "Code",
    tenantryLogin12: "Please Enter Your Code",
    tenantryLogin13: "New Password",
    tenantryLogin14: "Please Enter Your New Password",
    tenantryLogin15: "Check New Password",
    tenantryLogin16: "Please Enter Your Check New Password",
    tenantryLogin17: "Cancel",
    tenantryLogin18: "Save",
    tenantryLogin19: "Password Changed Successfully",
    tenantryLogin20: "Please Safeguard Your Account Information",
    tenantryLogin21: "Log In Again",
    tenantryLogin22: "Please Enter Password Again",
    tenantryLogin23: "The Two Password Entries Do Not Match!",
    tenantryLogin24: "Verification Successful",
    tenantryLogin25: "Please Try Again",
    tenantryLogin26: "Phone/Email Cannot Be Empty",
    tenantryLogin27: "Password Cannot Be Empty",
    tenantryLogin28: "The Password Must Contain at Least 7 Characters, Including at Least One Uppercase Letter, One Lowercase Letter, and One Number",
    tenantryLogin29: "Phone/Email Cannot Be Empty",
    tenantryLogin30: "Code Cannot Be Empty",
    tenantryLogin31: "Password Cannot Be Empty",
    tenantryLogin32: "The Password Must Contain at Least 7 Characters, Including at Least One Uppercase Letter, One Lowercase Letter, and One Number",
    tenantryLogin33: "Please Enter Password Again",
    tenantryLogin34: "My House",
    tenantryLogin35: "My Energy Billing",
    tenantryLogin36: "Recommend",
    tenantryLogin37: "Q&A",
    tenantryLogin38: "Verification Code Has Been Sent",
  },
  tenantryHome: {
    tenantryHome1: "Hello",
    tenantryHome2: "My House",
    tenantryHome3: "My Energy Billing",
    tenantryHome4: "Recommend",
    tenantryHome5: "Q&A",
    tenantryHome6: "My House",
    tenantryHome7: "View and Edit Your Personal and House Details",
    tenantryHome8: "My Energy Billing",
    tenantryHome9: "View My Energy Usage Details",
    tenantryHome10: "Recommend a Friend to Earn Points",
    tenantryHome11: "Recommend QUANTUM to Your Friends!",
    tenantryHome12: "My House",
    tenantryHome13: "My Energy Billing",
    tenantryHome14: "Recommend a Friend to Earn Points",
  },
  setting: {
    setting1: "Agent/Landlord",
    setting2: "Name",
    setting3: "Email",
    setting4: "Phone",
    setting5: "Edit Details",
    setting6: "Cancel",
    setting7: "Save",
    setting8: "Please Enter Your Name",
    setting9: "Please Enter Your Email",
    setting10: "Please Enter a Valid Email Address",
    setting11: "Please Enter a Valid Email Address",
    setting12: "Please Enter Your Phone",
    setting13: "All My Houses",
    setting14: "All My Houses Billing",
    setting15: "Setting",
    setting16: "Q&A",
    setting17: "User Information Has Been Changeed",
  },
  recommendFriend: {
    recommendFriend1: "Recommend",
    recommendFriend2: "1. After Successfully Recommending a Friend, Both You and Your Friend Will Receive 50 Points Each!",
    recommendFriend3: "2. You Can Recommend an Unlimited Number of People!",
    recommendFriend4: "3. You Will Send Them a Registration Link via Email.",
    recommendFriend5: "Your Friend's Name",
    recommendFriend6: "Your Friend's Email Address",
    recommendFriend7: "Sent",
    recommendFriend8: "Recommend",
    recommendFriend9: "Date",
    recommendFriend10: "Status",
    recommendFriend11: "Points",
    recommendFriend12: "Invited",
    recommendFriend13: "Earn",
    recommendFriend14: "Points After a Successful Invitation",
    recommendFriend15: "Cancel",
    recommendFriend16: "Continue",
    recommendFriend17: "Please Enter Your Name",
    recommendFriend18: "Please Enter Your Email",
    recommendFriend19: "Please Enter a Valid Email Address",
    recommendFriend20: "Please Enter a Valid Email Address",
    recommendFriend21: "My House",
    recommendFriend22: "My Energy Billing",
    recommendFriend23: "Recommend",
    recommendFriend24: "Q&A",
    recommendFriend25: "Activation",
    recommendFriend26: "NO Activation",
  },
  qa: {
    qa1: "We Have Compiled the Following Frequently Asked Questions to Provide Our Customers with the Most Detailed Information Possible.",
    qa2: "My House",
    qa3: "My Energy Billing",
    qa4: "Recommend",
    qa5: "Q&A",
    qa6: "All My Houses",
    qa7: "All My Houses Billing",
    qa8: "Setting",
    qa9: "Q&A",
  },
  myhouse: {
    myhouse1: "My House",
    myhouse2: "Tenant Information",
    myhouse3: "Name",
    myhouse4: "Email",
    myhouse5: "Phone",
    myhouse6: "Please Select the Area Code",
    myhouse7: "Please Enter Your Phone",
    myhouse8: "New Password",
    myhouse9: "Check New Password",
    myhouse10: "Cancel",
    myhouse11: "Save",
    myhouse12: "My House Details",
    myhouse13: "Postcode",
    myhouse14: "Address",
    myhouse15: "Start Date - End Date",
    myhouse16: "*If You Encounter Any Issues, Please Contact Us at",
    myhouse17: "My Energy Billing",
    myhouse18: "Roommate",
    myhouse19: "Number of Roommates",
    myhouse20: "Roommate",
    myhouse21: "Unlink",
    myhouse22: "Resend",
    myhouse23: "Roommate",
    myhouse24: "Please Enter: example@gmail.com",
    myhouse25: "Invite",
    myhouse26: "Please Enter Again",
    myhouse27: "The Two Password Entries Do Not Match!",
    myhouse28: "My House",
    myhouse29: "My Energy Billing",
    myhouse30: "Recommend",
    myhouse31: "Q&A",
    myhouse32: "Required",
    myhouse33: "Please Enter a Valid Email Address",
    myhouse34: "Please Enter a Valid Email Address",
    myhouse35: "Please Enter Your Name",
    myhouse36: "Please Enter Your Email",
    myhouse37: "Please Enter a Valid Email Address",
    myhouse38: "Please Enter a Valid Email Address",
    myhouse39: "Please Enter Your Phone",
    myhouse40: "Please Enter New Password",
    myhouse41: "The Password Must Contain at Least 7 Characters, Including at Least One Uppercase Letter, One Lowercase Letter, and One Number",
    myhouse42: "Please Enter Again",
  },
  housingEnergy: {
    housingEnergy1: "House Energy Overview",
    housingEnergy2: "Lease Dates",
    housingEnergy3: "Start Date",
    housingEnergy4: "To",
    housingEnergy5: "End Date",
    housingEnergy6: "Reset",
    housingEnergy7: "Address",
    housingEnergy8: "Tenants",
    housingEnergy9: "Lease End Date",
    housingEnergy10: "Action",
    housingEnergy11: "View Details",
    housingEnergy12: "All My Houses",
    housingEnergy13: "All My Houses Billing",
    housingEnergy14: "Setting",
    housingEnergy15: "Q&A",
  },
  energybill: {
    energybill1: "Billing",
    energybill2: "Status",
    energybill3: "GBP",
    energybill4: "Usage Chart",
    energybill5: "Please Select Energy",
    energybill6: "Please Select Month",
    energybill7: "Month",
    energybill8: "Month",
    energybill9: "My House",
    energybill10: "My Energy Billing",
    energybill11: "Recommend",
    energybill12: "Q&A",
  },
  allProperties: {
    allProperties1: "All My Houses",
    allProperties2: "Address",
    allProperties3: "Lease Dates",
    allProperties4: "Start Date",
    allProperties5: "To",
    allProperties6: "End Date",
    allProperties7: "Reset",
    allProperties8: "Add New House",
    allProperties9: "Address",
    allProperties10: "Tenants",
    allProperties11: "Lease End Date",
    allProperties12: "View Details",
    allProperties13: "Change House",
    allProperties14: "Postcode",
    allProperties15: "Postcode",
    allProperties16: "Address",
    allProperties17: "Please Select",
    allProperties18: "Change",
    allProperties19: "Add",
    allProperties20: "Cancel",
    allProperties21: "Postcode Cannot Be Empty",
    allProperties22: "Detailed Address Cannot Be Empty",
    allProperties23: "All My Houses",
    allProperties24: "All My Houses Billing",
    allProperties25: "Setting",
    allProperties26: "Q&A",
    allProperties27: "Edit Address",
    allProperties28: "Add new Address",
    allProperties29: "Successufl",
  },
  agencySignin: {
    agencySignin1: "Sign Up",
    agencySignin3: "First Name",
    agencySignin4: "Please Enter Your First Name",
    agencySignin5: "Last Name",
    agencySignin6: "Please Enter Your Last Name",
    agencySignin7: "Please Choose Your Preferred Method to Receive the Verification Code (Phone  or Email)",
    agencySignin8: "Your Phone Number",
    agencySignin9: "Please Select the Area Code",
    agencySignin10: "Please Enter Your Phone",
    agencySignin11: "Email",
    agencySignin12: "Please Enter Your Email Address",
    agencySignin13: "Please Drag the Slider to Complete the Puzzle",
    agencySignin14: "Code",
    agencySignin15: "Enter Your Code",
    agencySignin16: "I Have Read and Agree to the Terms of Service",
    agencySignin17: "We Move In on the Lease Start Date",
    agencySignin18: "Lease",
    agencySignin19: "Verification Code Has Been Sent",
    agencySignin20: "Please Try Again",
    agencySignin21: "Please Enter Your Code",
    agencySignin22: "Please Enter Your First Name",
    agencySignin23: "Please Enter Your Last Name",
    agencySignin24: "Please Enter Lease Start Date",
    agencySignin25: "Please Enter Lease End Date",
    agencySignin26: "Please Enter Your Phone",
    agencySignin27: "Please Enter Your Email",
    agencySignin28: "Please Enter a Valid Email Address",
    agencySignin29: "Please Enter a Valid Email Address",
    agencySignin30: "Verification Code Has Been Sent",
    agencySignin31: "Please Move the Slider Again",
    agencySignin32: "Verification Failed",
    agencySignin33: "Sign Up Successful",
  },
  agencyLogin: {
    agencyLogin1: "Agent/Landlord Log In",
    agencyLogin2: "Log In to Your Account",
    agencyLogin3: "Please Enter Your Phone/Email",
    agencyLogin4: "Please Enter Your Password",
    agencyLogin5: "Forgot Password",
    agencyLogin6: "Please Drag the Slider to Complete the Puzzle",
    agencyLogin7: "Change Password",
    agencyLogin8: "Phone/Email",
    agencyLogin9: "Please Enter Your Phone",
    agencyLogin10: "Sent",
    agencyLogin11: "Code",
    agencyLogin12: "Please Enter Your Code",
    agencyLogin13: "New Password",
    agencyLogin14: "Please Enter Your New Password",
    agencyLogin15: "Check New Password",
    agencyLogin16: "Please Enter Your Check New Password",
    agencyLogin17: "Cancel",
    agencyLogin18: "Save",
    agencyLogin19: "Password Changed Successfully",
    agencyLogin20: "Please Safeguard Your Account Information",
    agencyLogin21: "Log In Again",
    agencyLogin22: "Please Enter Password Again",
    agencyLogin23: "The Two Password Entries Do Not Match!",
    agencyLogin24: "Verification Successful",
    agencyLogin25: "Please Try Again",
    agencyLogin26: "Phone/Email Cannot Be Empty",
    agencyLogin27: "Password Cannot Be Empty",
    agencyLogin28: "The Password Must Contain at Least 7 Characters, Including at Least One Uppercase Letter, One Lowercase Letter, and One Number",
    agencyLogin29: "Phone/Email Cannot Be Empty",
    agencyLogin30: "Code Cannot Be Empty",
    agencyLogin31: "Password Cannot Be Empty",
    agencyLogin32: "The Password Must Contain at Least 7 Characters, Including at Least One Uppercase Letter, One Lowercase Letter, and One Number",
    agencyLogin33: "Please Enter Password Again",
    agencyLogin34: "All My Houses",
    agencyLogin35: "All My Houses Billing",
    agencyLogin36: "Setting",
    agencyLogin37: "Q&A",
    agencyLogin38: "Verification Code Has Been Sent",
  },
  agencyHome: {
    agencyHome1: "Hello",
    agencyHome2: "Agent/Landlord",
    agencyHome3: "Welcome to Your Online Dashboard",
    agencyHome4: "All My Houses",
    agencyHome5: "All My Houses Billing",
    agencyHome6: "Setting",
    agencyHome7: "Q&A",
    agencyHome8: "All My Houses",
    agencyHome9: "House Energy Overview",
    agencyHome10: "Setting",
    agencyHome11: "View and Edit Your Personal and House Details",
    agencyHome12: "View My Energy Usage Details",
    agencyHome13: "View and Edit Your House or Update Personal Details",
    agencyHome14: "All My Houses",
    agencyHome15: "House Energy Overview",
    agencyHome16: "Setting",
  }
};
